import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class DistributorService {
    readDistributorQuery(){
        var query = `query($paging:ServerPaging, $type:String, $status:String){
                        GetContact(Paging:$paging, ContactType:$type, ActiveFlag:$status){
                            contact_id
                            contact_code
                            contact_type
                            contact_name
                            status
                            metadata
                            contact_debit
                        }
                    }`;
        return query;
    }

    async getDetailContact(id){
        const variables = {
            id : id
        }
        var query = gql`query($id:Int){
            GetContact(ContactId:$id){
                created_at
                last_update
                contact_id
                contact_code
                contact_type
                contact_name
                status
                metadata
                attachment1
                attachment2
                contact_group_types {
                    created_at
                    last_update
                    contact_id
                    group_type_id
                    group_type_name
                    top_project
                    top_retail
                }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetContact[0];
    }

    readHistoryLimitQuery(){
        var query = `query($id:Int,$type:String!){
                        GetContact(ContactId:$id,ContactType:$type){
                            metadata
                        }
                    }`;
        return query;
    }

    readAddressQuery(){
        var query = `query($id:Int,$type:String!){
                        GetContact(ContactId:$id,ContactType:$type){
                            metadata
                        }
                    }`;
        return query;
    }

    readSalesQuery(){
        var query = `query($id:Int,$type:String!){
                        GetContact(ContactId:$id,ContactType:$type){
                            metadata
                        }
                    }`;
        return query;
    }

    async addQuery(variables){
        var query = gql`mutation($data:NewContact!){
            CreateContact(contact:$data){
              response
            }
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`mutation($id:Int!,$data:NewContact!){
            UpdateContact(contactID:$id,contact:$data){
              response
            }
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async deleteQuery(variables){
        var query = gql`mutation($id:Int!){
            DeleteContact(contactID:$id){
                response
            }
        }`;

        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    nameDuplicateValidation(data){
        var valueArr = data.map(function(item){ return item.sales_name });
        var isDuplicate = valueArr.some(function(item, idx){ 
            return valueArr.indexOf(item) != idx 
        });

        return isDuplicate
    }

    addressDuplicateValidation(data){
        var valueArr = data.map(function(item){ return item });
        var isDuplicate = valueArr.some(function(item, idx){ 
            return valueArr.indexOf(item) != idx 
        });

        return isDuplicate
    }

    groupTypeDuplicateValidation(data){
        var valueArr = data.map(function(item){ return item.group_type_id });
        var isDuplicate = valueArr.some(function(item, idx){ 
            return valueArr.indexOf(item) != idx 
        });

        return isDuplicate
    }

    async getAreaQuery(){
        var query = gql`query{
            GetAreaCode{
                areacode
                areaname
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetAreaCode;
        var areaData = [];
        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var str = { value: data[i].areacode, 
                            label: data[i].areacode + ' (' + data[i].areaname + ')'}
                areaData.push(str);
            }
        }
        return areaData
    }

    async getGroupTypeQuery(){
        const query = gql`query{
            GetGroupType{
                group_type_id
                group_type_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetGroupType;
        var groupTypeData = [];
        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var str = { value: data[i].group_type_id, 
                            label: data[i].group_type_name }
                groupTypeData.push(str);
            }
        }
        return groupTypeData
    }

    async statusButtonGenerator(statusList, type){
        var variables = {
            type : type
        };

        const query = gql`query($type:String){
            GetContactCount(type:$type){
                status
                count
            }
        }`;

        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        var data = result.data.GetContactCount;

        var start = '<div class="btn-group w-100">',
            main = '',
            end = '</div><hr>';

        var countAll = 0;
        if(statusList != null){
            for (let i = 0; i < statusList.length; i++){
                var status = statusList[i].toString().replace(/\s/g, '');
                var count = 0;
                for (let j = 0; j < data.length; j++){
                    if(data[j].status == status.substring(0, 1)){
                        count = data[j].count;
                    }
                }
                countAll = countAll + count;

                var str = '<button id="btn' + status + '" style="width:12%" class="btn btn-secondary btn-'+ status +'" >' + status + '&nbsp;<b>'+ count.toString() + '</b></button>&nbsp;';
                main = main + str;
            }
        }
        
        var all = '<button id="btnAll" style="width:12%" class="btn btn-secondary btn-All">All&nbsp;<b>'+ countAll.toString() +'</b></button>&nbsp;'

        return (start + all + main + end);
    }
}

export default new DistributorService();